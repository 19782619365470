import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"

const HomeSwipeOne = () => (
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    effect={"fade"}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    pagination={true}
    modules={[Autoplay, EffectFade, Pagination]}
  >
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396490/Landing%20Page/500x600/NEW%2021032023/BYJ0331_nzlauo.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396490/Landing%20Page/500x600/NEW%2021032023/BYJ0327_usa6d9.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528280/Landing%20Page/500x600/Stack_Rings_yozljh.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528279/Landing%20Page/500x600/az5420_tsedui.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396488/Landing%20Page/500x600/NEW%2021032023/BYJ0024_zz6hwk.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396489/Landing%20Page/500x600/NEW%2021032023/BYJ0088_wky3qt.jpg"
      />
    </SwiperSlide>
  </Swiper>
)

export default HomeSwipeOne
