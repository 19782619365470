import * as React from "react"
import CenterLogo from "../../components/CenterLogo"
import H2 from "../../components/H2"
import Logo from "../../components/Logo"
import Relative from "../../components/Relative"
import BtnLink from "../../components/BtnLink"

const HomeSectionOne = () => (
  <>
    <div className="flex flex-wrap py-16 bg-white">
      <div className="item w-full h-auto text-center md:py-9 py-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="md:text-6xl text-4xl">About Beyond Jewelry</H2>
        </Relative>
      </div>

      <div className="container mx-auto md:px-16 px-4 ">
        <div className="item w-full h-auto text-center  md:py-14 md:px-14 py-4 px-4">
          <p className="md:text-2xl text-lg leading-10">
            Beyond Jewelry GmbH has been established as a subsidiary of Advance
            Manufacturers Co., Ltd, one of Thailand’s leading jewelry
            manufacturers. Beyond Jewelry GmbH is your partner for creating
            customized jewelry as well as private label collections. We closely
            support you with professional and experienced service in design,
            development & production of jewelry. We work with established
            European jewelry companies, who would like to generate additional
            business by adding their own unique products as well as emerging
            brands and companies looking to build their own large scale jewelry
            brands.
          </p>
          <br /> <br />
          <BtnLink to="/about-beyond-jewelry">Read more</BtnLink>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap pb-12 md:pb-16 bg-white">
      <div className="item w-full h-auto text-center md:py-0 py-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl ">
            Design &amp; development
          </H2>
        </Relative>
      </div>
    </div>
  </>
)

export default HomeSectionOne
