import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Autoplay, Pagination, Navigation } from "swiper"
import "swiper/scss/effect-fade"
import "swiper/scss"
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import styled from "styled-components"

const Img = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const HomeSlide = () => {
  return (
    <div className="md:pt-38 pt-40">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="HomeSwiper"
      >
        <SwiperSlide>
          <Img
            className="composition__photo composition__photo--p1"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528261/Landing%20Page/Woman_Model_mfveip.jpg"
            alt="Extensive range of products"
          />
        </SwiperSlide>
        <SwiperSlide>
          <Img
            className="composition__photo composition__photo--p1"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528261/Landing%20Page/ADV_BYJ_y3lvgo.jpg"
            alt="Private Label Jewelry"
          />
        </SwiperSlide>

        <SwiperSlide>
          <Img
            className="composition__photo composition__photo--p1"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528261/Landing%20Page/Sustainability_lfub2n.jpg"
            alt="Beyond gold"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default HomeSlide
