import * as React from "react"
import BtnLink from "../../components/BtnLink"
import LeftLogo from "../../components/LeftLogo"
import PaddingLeftLogo from "../../components/PaddingLeftLogo"
import H2 from "../../components/H2"
import Logo from "../../components/Logo"
import Relative from "../../components/Relative"
import styled from "styled-components"

const LeftLogoD = styled.div`
  width: 130px;
  height: 130px;
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 90px;
    height: 90px;
    top: -6px;
  }
`

const HomeSectionTwo = () => (
  <div className="container mx-auto">
    <div className="flex flex-wrap">
      <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-1  md:order-1 lg:order-1">
        <img
          className="object-contain md:object-scale-down f-r"
          alt="a changer"
          src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528282/Landing%20Page/825x825/Design_btzch7.jpg"
        />
      </div>
      <div className="item w-full md:w-full lg:w-1/2 h-auto order-2  md:order-2  lg:order-2  md:py-14 md:px-14 py-10 px-4">
        <PaddingLeftLogo />
        <Relative>
          <LeftLogoD>
            <Logo />
          </LeftLogoD>
          <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl  pl-6 md:pl-10 lg:pt-5 lg:py-2">
            Design &amp;
            <br /> Development
          </H2>
        </Relative>
        <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
          We provide design and development services to produce customized
          products as well as complete jewelry collections. All designs are
          created by our team of in-house designers. Individually hand drawn
          designs as well as CAD design services are provided. With our
          extensive knowledge in the jewelry industry, we will help you plan,
          sample and produce a private label jewelry collection from start to
          finish.
        </p>
        <div className=" md:pl-12">
          <BtnLink to="/design-development">Read More</BtnLink>
        </div>
      </div>
      <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-14 md:px-14 py-10 px-4">
        <PaddingLeftLogo />
        <Relative>
          <LeftLogo>
            <Logo />
          </LeftLogo>
          <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl  pl-6 md:pl-10 lg:pt-2 md:py-2">
            Production
          </H2>
        </Relative>
        <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
          Advance Manufacturers Co., was established in 1989 and is based in
          Ladkrabang Industrial Estate which is a free trading zone located near
          Suvarnabhumi International Airport in Bangkok. Advance Manufacturers
          has over 450 skilled employees, produces 3 million pieces of jewelry
          per year and has a monthly model making capacity of about 600 designs.
        </p>
        <div className=" md:pl-12">
          <BtnLink to="/production">Read More</BtnLink>
        </div>
      </div>

      <div className="item  w-full md:w-full lg:w-1/2 h-full order-3  md:order-3 lg:order-4">
        <img
          className="object-contain md:object-scale-down f-l"
          alt="a changer"
          src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528281/Landing%20Page/825x825/Production_snbvqb.jpg"
        />
      </div>
      <div className="item  w-full md:w-full lg:w-1/2 h-full order-5  md:order-5 lg:order-5">
        <img
          className="object-contain md:object-scale-down f-r"
          alt="a changer"
          src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1680078814/Landing%20Page/825x825/BYJ0305-BYJ0307_revise_npnx69.jpg"
        />
      </div>
      <div className="item w-full md:w-full lg:w-1/2 h-auto order-6  md:order-6 lg:order-6  md:py-14 md:px-14 py-10 px-4">
        <PaddingLeftLogo />
        <Relative>
          <LeftLogo>
            <Logo />
          </LeftLogo>
          <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl  pl-6 md:pl-10 lg:pt-2 lg:py-2">
            Products
          </H2>
        </Relative>
        <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
          BYJ offers a wide range of jewelry categories including ear studs,
          hoops, pendants, rings necklaces, & bracelets. We work with 8K to 18K
          gold, sterling silver as well as brass metal. Used stones include but
          are not limited to precious and semi-precious stones, diamonds
          (natural & lab grown) cubic zirconia and crystals. For all used raw
          materials we place importance on sourcing conflict free materials for
          our partners. We also offer recycled metals and packaging material on
          request.
        </p>
        <div className=" md:pl-12">
          <BtnLink to="/products">Read More</BtnLink>
        </div>
      </div>
    </div>
  </div>
)

export default HomeSectionTwo
