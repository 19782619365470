import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import OneSlideOne from "../../images/500X600.jpg"
import Red from "../../images/500X600-red.jpg"

const HomeSwipeThree = () => (
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    effect={"fade"}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    pagination={true}
    modules={[Autoplay, EffectFade, Pagination]}
  >
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528280/Landing%20Page/500x600/ave5761_idg2xh.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528281/Landing%20Page/500x600/avr5218_kgs9pk.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528279/Landing%20Page/500x600/BYJ0053_wm0ihu.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528280/Landing%20Page/500x600/ave5812_h9xmsg.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528279/Landing%20Page/500x600/ave5857_ewjn7z.jpg"
      />
    </SwiperSlide>
  </Swiper>
)

export default HomeSwipeThree
