import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import OneSlideOne from "../../images/500X600.jpg"
import Red from "../../images/500X600-red.jpg"

const HomeSwipeTwo = () => (
  <Swiper
    spaceBetween={30}
    centeredSlides={true}
    effect={"fade"}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
    }}
    pagination={true}
    modules={[Autoplay, EffectFade, Pagination]}
  >
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528281/Landing%20Page/500x600/byj0236_taqmll.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528281/Landing%20Page/500x600/byj0035_uw6cqq.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396488/Landing%20Page/500x600/NEW%2021032023/byj0038_g8fsau.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1678528281/Landing%20Page/500x600/ave5724_wzekwz.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396488/Landing%20Page/500x600/NEW%2021032023/BYJ0288_me0xwj.jpg"
      />
    </SwiperSlide>
    <SwiperSlide>
      <img
        className="object-contain md:object-scale-down f-r"
        src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1679396488/Landing%20Page/500x600/NEW%2021032023/azp5855_eoeeej.jpg"
      />
    </SwiperSlide>
  </Swiper>
)

export default HomeSwipeTwo
