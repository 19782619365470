import { Link } from "gatsby"
import styled from "styled-components"

const BtnLink = styled(Link)`
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0px;
  font-family: Arial;
  color: #515151;
  font-size: 30px;
  background: transparent;
  padding: 10px 20px 10px 20px;
  border: solid #515151 1px;
  text-decoration: none;

  :hover {
    background: #292929;
    text-decoration: none;
    color: #fff;
  }
`

export default BtnLink
