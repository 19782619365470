import * as React from "react"

import Layout from "../components/layout"

import Seo from "../components/seo"

import HomeSlide from "../containers/Home/HomeSlide"

import HomeSectionOne from "../containers/Home/HomeSectionOne"
import Bg from "../components/Bg"
import HomeSectionThree from "../containers/Home/HomeSectionThree"
import HomeSectionTwo from "../containers/Home/HomeSectionTwo"
import ContactFormer from "../components/ContactFormer"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Beyond Jewelry - Custom-Made High-Quality Jewelry from Skilled GoldSmiths"
      description="At Beyond Jewelry, we offer custom-made, high-quality jewelry crafted by our team of skilled craftsmen. Our services include prototyping, mass production, and finishing, ensuring that you receive the perfect piece of jewelry. Contact us today to start your unique jewelry project."
    />
    <HomeSlide />
    <HomeSectionOne />
    <Bg>
      <HomeSectionTwo />
    </Bg>
    <HomeSectionThree />

    <Bg>
      <ContactFormer />
    </Bg>
  </Layout>
)

export default IndexPage