import * as React from "react"
import CenterLogo from "../../components/CenterLogo"

import Logo from "../../components/Logo"
import Relative from "../../components/Relative"
import IconPackaging from "../../images/landing/packing-icon.png"
import IconLabeling from "../../images/landing/labeling-icon.png"
import IconImport from "../../images/landing/import-icon.png"
import IconQuality from "../../images/landing/quality-icon.png"

import HomeSwipeOne from "./HomeSwipeOne"
import HomeSwipeTwo from "./HomeSwipeTwo"
import HomeSwipeThree from "./HomeSwipeThree"
import H2 from "../../components/H2"
const HomeSectionThree = () => (
  <div className="container mx-auto ">
    <div className="flex flex-wrap py-6 ">
      <div className="item w-full h-auto text-center md:py-12 py-12">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>{" "}
          <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-2 lg:pt-0 pt-4">
            Services
          </H2>
        </Relative>
      </div>
    </div>
    <div className="flex flex-wrap overflow-hidden py-2">
      <div className="pt-8 md:pt-0 w-full overflow-hidden lg:w-1/4 xl:w-1/4">
        <img src={IconPackaging} alt="" className="mx-auto h-16 md:h-32" />
        <p className="text-2xl text-center pt-4 md:pt-6 pb-6 md:pb-0  align-text-bottom font-bold">
          Packaging
        </p>
      </div>
      <div className="pt-8 md:pt-0 w-full overflow-hidden lg:w-1/4 xl:w-1/4">
        <img src={IconLabeling} alt="" className="mx-auto h-16 md:h-32" />
        <p className="text-2xl text-center pt-4 md:pt-6 pb-6 md:pb-0 align-text-bottom font-bold">
          Labeling
        </p>
      </div>
      <div className="pt-8 md:pt-0 w-full overflow-hidden lg:w-1/4 xl:w-1/4">
        <img src={IconImport} alt="" className="mx-auto h-16 md:h-32" />
        <p className="text-2xl text-center pt-4 md:pt-6 pb-6 md:pb-0  align-text-bottom font-bold">
          Import
        </p>
      </div>
      <div className="pt-8 md:pt-0 w-full overflow-hidden lg:w-1/4 xl:w-1/4">
        <img src={IconQuality} alt="" className="mx-auto h-16 md:h-32" />
        <p className="text-2xl text-center pt-4 md:pt-6 pb-6 md:pb-0  align-text-bottom font-bold">
          Quality
        </p>
      </div>
    </div>

    <div className="flex flex-wrap md:py-12 py-12 md:pt-12">
      <div className="item  w-full text-center ">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="2xl:text-5xl xl:text-5xl lg:text-4xl text-4xl xl:pt-2 lg:pt-0 pt-4">
            Products{" "}
          </H2>
        </Relative>
      </div>
    </div>
    <div className="flex flex-wrap overflow-hidden lg:-mx-2 xl:-mx-2">
      <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
        <HomeSwipeOne />
        <h3 className="text-center text-3xl py-6 md:py-12">Gold</h3>
      </div>
      <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
        <HomeSwipeTwo />{" "}
        <h3 className="text-center text-3xl py-6 md:py-12">Silver</h3>
      </div>
      <div className="w-full overflow-hidden lg:my-2 lg:px-2 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/3">
        <HomeSwipeThree />{" "}
        <h3 className="text-center text-3xl py-6 md:py-12">Brass</h3>
      </div>
    </div>
  </div>
)

export default HomeSectionThree
